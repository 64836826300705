export const locale = {
  lang: 'en',
  data: {
    MENU: {
      DASHBOARD:{
        SECTION:'DASHBOARD',
        OVERVIEW: 'overview'
      },
      MANAGEMENT:{
        SECTION:'MANAGEMENT',
        LIST: 'LIST'
      },
      ORDERS:{
        SECTION:'ORDERS',
        LIST: 'LIST'
      },
      CREDENTIALS : {
        SECTION: 'CREDENTIAL',
        LIST: 'LIST'
      },
      TRANSACTIONS : {
        SECTION: 'TRANSACTIONS',
        LIST: 'LIST'
      },
      MERCHANTS : {
        SECTION: 'MERCHANTS',
        LIST: 'LIST'
      },
      QUERIES : {
        SECTION: 'MERCHANTS',
        LIST: 'LIST'
      } 
    }
  }
}
