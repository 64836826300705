import { Role } from './role';

export class User {
  uuid: string;
  name: string;
  phone_number: string;
  email: string;
  type: string;
  // id: number;
  // email: string;
  // password: string;
  // firstName: string;
  // lastName: string;
  // avatar: string;
  // role: Role;
  // token?: string;
}
