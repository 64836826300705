import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { User, Role } from 'app/core/auth/models';
import { ToastrService } from 'ngx-toastr';
import { ApiResponse } from 'app/common/types';

interface UserLoginResponsePayload {
  token: string
  refresh_token: string
  user: User
}

type UserLoginResponse  = ApiResponse<UserLoginResponsePayload>

type UserStoreObj = User & { token: string }


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  //public
  public currentUser: Observable<UserStoreObj>;

  //private
  private currentUserSubject: BehaviorSubject<UserStoreObj>;

  /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
  constructor(private _http: HttpClient, private _toastrService: ToastrService) {
    this.currentUserSubject = new BehaviorSubject<UserStoreObj>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  // getter: currentUserValue
  public get currentUserValue(): UserStoreObj {
    return this.currentUserSubject.value;
  }

  /**
   *  Confirms if user is admin
   */
  get isAdmin() {
    return this.currentUser && this.currentUserSubject.value.type === Role.Admin;
  }

  /**
   *  Confirms if user is client
   */
  get isClient() {
    return this.currentUser && this.currentUserSubject.value.type === Role.Client;
  }

  getAccessToken() {
    return `Bearer ${JSON.parse(localStorage.getItem('currentUser'))?.token ?? ""}`;
  }

  /**
   * User login
   *
   * @param phone_number
   * @param password
   * @returns user
   */
  login(phone_number: string, password: string) {
    return this._http
      .post<any>(`${environment.apiUrl}/v1/manpower/yelow/login`, { phone_number, password })
      .pipe(
        map((response: UserLoginResponse) => {
          const {token, user} = response?.payload
          // login successful if there's a jwt token in the response
          if (token && user) {
            var userObj: UserStoreObj = { ...user, token }
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(userObj));

            // Display welcome toast!
            setTimeout(() => {
              this._toastrService.success(
                'You have successfully logged in as an ' +
                userObj.type +
                  ' user to Vuexy. Now you can start to explore. Enjoy! 🎉',
                '👋 Welcome, ' + user.name + '!',
                { toastClass: 'toast ngx-toastr', closeButton: true }
              );
            }, 2500);

            // notify
            this.currentUserSubject.next(userObj);
          }

          return userObj;
        })
      );
  }

  /**
   * User logout
   *
   */
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    // notify
    this.currentUserSubject.next(null);
  }
}
