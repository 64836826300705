import { CoreMenu } from "@core/types";

export const menu: CoreMenu[] = [
  {
    id: "Dashboard",
    type: "section",
    title: "Dashboard",
    translate: "MENU.DASHBOARD.SECTION",
    icon: "package",
    children: [
      {
        id: "overview",
        title: "Overview",
        translate: "MENU.DASHBOARD.OVERVIEW",
        type: "item",
        role: ["Admin"], //? To set multiple role: ['Admin', 'Client']
        icon: "home",
        url: "dashboard/overview",
      },
    ],
  },
  // {
  //   id: 'Users',
  //   type: 'section',
  //   title: 'Users',
  //   translate: 'MENU.USERSLIST.SECTION',
  //   icon: 'user',
  //   children:[
  {
    id: 'Management',
    type: 'section',
    title: 'Management',
    translate: 'MENU.MANAGEMENT.SECTION',
    children:[
      {
        id: 'Users',
        title: 'Users',
        translate: 'MENU.MANAGEMENT.LIST',
        type: 'item',
        role: ['Admin'], //? To set multiple role: ['Admin', 'Client']
        icon: 'users',
        url: 'users/list'
      },
      {
        id: "Orders List",
        title: "Orders",
        translate: "MENU.ORDERS.SECTION",
        type: "item",
        role: ["Admin"], //? To set multiple role: ['Admin', 'Client']
        icon: "shopping-cart",
        url: "orders/list",
      },
      {
        id: "Credentials List",
        title: "Credentials",
        translate: "MENU.CREDENTIALS.SECTION",
        type: "item",
        role: ["Admin"], //? To set multiple role: ['Admin', 'Client']
        icon: "log-in",
        url: "credentials/list",
      },
      {
        id: "Transactions",
        title: "Transactions",
        translate: "MENU.TRANSACTIONS.SECTION",
        type: "item",
        role: ["Admin"], //? To set multiple role: ['Admin', 'Client']
        icon: "credit-card",
        url: "transactions/list",
      },
      {
        id: "Merchants",
        title: "Merchants",
        translate: "MENU.MERCHANTS.SECTION",
        type: "item",
        role: ["Admin"], //? To set multiple role: ['Admin', 'Client']
        icon: "user",
        url: "merchants/list",
      },
      {
        id: "UserQueries",
        title: "User Queries",
        translate: "MENU.QUERIES.LIST",
        type: "item",
        role: ["Admin"], //? To set multiple role: ['Admin', 'Client']
        icon: "mail",
        url: "queries/list",
      },
    ]
  }
]
