import { Pipe, PipeTransform } from '@angular/core';
import { Address } from 'app/common/models';

@Pipe({
  name: 'address'
})
export class AddressPipe implements PipeTransform {

  transform(value: Address, ...args: unknown[]): string {
    return `${value.address_line_1}, ${value.area}, Near ${value.landmark}, ${value.city}, ${value.state}, ${value.country} ${value.pincode} (${value.address_type})`;
  }

}
