import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import 'hammerjs';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr'; // For auth after login toast

import { CoreModule } from '@core/core.module';
import { CoreCommonModule } from '@core/common.module';
import { CoreSidebarModule, CoreThemeCustomizerModule } from '@core/components';

import { coreConfig } from 'app/app-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { TokenInterceptorService } from './common/service/token-interceptor.service';
import { TranslateModule } from '@ngx-translate/core';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { TransactionsTableComponent } from './common/component/transactions-table/transactions-table.component';
import { ChatWindowComponent } from './common/component/custom-components/chat-window/chat-window.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: '/dashboard/overview',
    pathMatch: 'full'
  },
  {
    path:"users",
    loadChildren: () => import('./core/user-management/user-management.module').then(m => m.UserManagementModule)
  },
  {
    path: "orders",
    loadChildren: () => import('./core/order-management/order-management.module').then(m => m.OrderManagementModule)
  },
  {
    path: "transactions",
    loadChildren: () => import('./core/transactions-management/transactions-management.module').then(m => m.TransactionsManagementModule)
  },
  {
    path: "credentials",
    loadChildren: () => import('./core/credentials-management/credentials-management.module').then(m => m.CredentialsManagementModule)
  },
  {
    path: "merchants",
    loadChildren: () => import('./core/merchant-management/merchant-management.module').then(m => m.MerchantManagementModule)
  },
  {
    path: 'queries',
    loadChildren: () => import('./core/queries/queries.module').then(m => m.QueriesModule)
  },
  {
    path: '',
    loadChildren: () => import('./core/pages/pages.module').then(m => m.PagesModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./core/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: '**',
    redirectTo: '/miscellaneous/error' //Error 404 - Page not found
  }
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: 'enabled', // Add options right here
      relativeLinkResolution: 'legacy'
    }),
    NgbModule,
    ToastrModule.forRoot(), 
    SweetAlert2Module.forRoot(),
    CoreModule.forRoot(coreConfig),
    CoreCommonModule,
    CoreSidebarModule,
    PerfectScrollbarModule,
    CoreThemeCustomizerModule,
    LayoutModule,
    TranslateModule.forRoot()
  ],

  bootstrap: [AppComponent],
  providers:[
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true }
  ]
})
export class AppModule {}
